.Polaris-DataTable__Table thead {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.tickets-list-table-wrapper .Polaris-IndexTable__TableHeading {
  color: #202224;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  background-color: #f5f5f5 !important;
  margin-left: 35px;
}

.tickets-list-table-wrapper
  .Polaris-IndexTable__Table.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  tr
  th
  .Polaris-Text--root.Polaris-Text--bodySm.Polaris-Text--medium {
  font-size: 13px !important;
  font-weight: 650;
}

.tickets-list-table-wrapper
  .Polaris-IndexTable__Table.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  tr
  th.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable {
  padding-left: 45px !important;
}

.tickets-list-table-wrapper
  .Polaris-Badge
  .Polaris-Text--root.Polaris-Text--bodySm {
  color: #616161;
  font-size: 13px;
  font-weight: 600;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL3RpY2tldHMvY29tcG9uZW50cy90YWJsZS90YWJsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFBQSxrQkFBbUI7RUFBbkI7QUFBbUI7O0FBR3JCO0VBQ0UsY0FBYztFQUNkLDRCQUE0QjtFQUM1QiwrQkFBK0I7RUFDL0Isb0NBQW9DO0VBQ3BDLGlCQUFpQjtBQUNuQjs7QUFFQTs7Ozs7RUFLRSwwQkFBMEI7RUFDMUIsZ0JBQWdCO0FBQ2xCOztBQUVBOzs7O0VBSUUsNkJBQTZCO0FBQy9COztBQUVBOzs7RUFHRSxjQUFjO0VBQ2QsZUFBZTtFQUNmLGdCQUFnQjtBQUNsQiIsImZpbGUiOiJzcmMvZmVhdHVyZS90aWNrZXRzL2NvbXBvbmVudHMvdGFibGUvdGFibGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLlBvbGFyaXMtRGF0YVRhYmxlX19UYWJsZSB0aGVhZCB7XG4gIEBhcHBseSBiZy1bI0Y1RjVGNV07XG59XG5cbi50aWNrZXRzLWxpc3QtdGFibGUtd3JhcHBlciAuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZUhlYWRpbmcge1xuICBjb2xvcjogIzIwMjIyNDtcbiAgcGFkZGluZy10b3A6IDE1cHggIWltcG9ydGFudDtcbiAgcGFkZGluZy1ib3R0b206IDE1cHggIWltcG9ydGFudDtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNSAhaW1wb3J0YW50O1xuICBtYXJnaW4tbGVmdDogMzVweDtcbn1cblxuLnRpY2tldHMtbGlzdC10YWJsZS13cmFwcGVyXG4gIC5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUtLXVuc2VsZWN0YWJsZS5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlLS1zdGlja3lcbiAgdHJcbiAgdGhcbiAgLlBvbGFyaXMtVGV4dC0tcm9vdC5Qb2xhcmlzLVRleHQtLWJvZHlTbS5Qb2xhcmlzLVRleHQtLW1lZGl1bSB7XG4gIGZvbnQtc2l6ZTogMTNweCAhaW1wb3J0YW50O1xuICBmb250LXdlaWdodDogNjUwO1xufVxuXG4udGlja2V0cy1saXN0LXRhYmxlLXdyYXBwZXJcbiAgLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS0tdW5zZWxlY3RhYmxlLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUtLXN0aWNreVxuICB0clxuICB0aC5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy0tc2Vjb25kLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGVIZWFkaW5nLS11bnNlbGVjdGFibGUge1xuICBwYWRkaW5nLWxlZnQ6IDQ1cHggIWltcG9ydGFudDtcbn1cblxuLnRpY2tldHMtbGlzdC10YWJsZS13cmFwcGVyXG4gIC5Qb2xhcmlzLUJhZGdlXG4gIC5Qb2xhcmlzLVRleHQtLXJvb3QuUG9sYXJpcy1UZXh0LS1ib2R5U20ge1xuICBjb2xvcjogIzYxNjE2MTtcbiAgZm9udC1zaXplOiAxM3B4O1xuICBmb250LXdlaWdodDogNjAwO1xufVxuIl19 */