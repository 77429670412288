.awards-modal .Polaris-Modal-Section .Polaris-Box {
  padding-inline: 0px !important;
}

.awards-modal table thead tr th {
  font-weight: 550 !important;
  font-size: 13px !important;
  color: #303030 !important;
  background-color: #ffffff !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL2F3YXJkcy1jcmVhdGUtbW9kYWwvc3R5bGVzLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDhCQUE4QjtBQUNoQzs7QUFFQTtFQUNFLDJCQUEyQjtFQUMzQiwwQkFBMEI7RUFDMUIseUJBQXlCO0VBQ3pCLG9DQUFvQztBQUN0QyIsImZpbGUiOiJzcmMvZmVhdHVyZS9ldmVudHMvY29tcG9uZW50cy9hd2FyZHMtY3JlYXRlLW1vZGFsL3N0eWxlcy5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYXdhcmRzLW1vZGFsIC5Qb2xhcmlzLU1vZGFsLVNlY3Rpb24gLlBvbGFyaXMtQm94IHtcbiAgcGFkZGluZy1pbmxpbmU6IDBweCAhaW1wb3J0YW50O1xufVxuXG4uYXdhcmRzLW1vZGFsIHRhYmxlIHRoZWFkIHRyIHRoIHtcbiAgZm9udC13ZWlnaHQ6IDU1MCAhaW1wb3J0YW50O1xuICBmb250LXNpemU6IDEzcHggIWltcG9ydGFudDtcbiAgY29sb3I6ICMzMDMwMzAgIWltcG9ydGFudDtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ZmZmZmZiAhaW1wb3J0YW50O1xufVxuIl19 */