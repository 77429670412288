.events-list-wrapper .Polaris-DataTable {
  border-radius: 0 !important;
}
.events-list-wrapper .Polaris-DataTable thead th {
  font-weight: 600 !important;
  font-size: 12px;
}

.event-create-tabs .Polaris-Badge {
  background-color: #FFEF9D !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2V2ZW50cy9yb3V0ZXMvc3R5bGVzLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDJCQUEyQjtBQUM3QjtBQUNBO0VBQ0UsMkJBQTJCO0VBQzNCLGVBQWU7QUFDakI7O0FBRUE7RUFDRSxvQ0FBb0M7QUFDdEMiLCJmaWxlIjoic3JjL2ZlYXR1cmUvZXZlbnRzL3JvdXRlcy9zdHlsZXMuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmV2ZW50cy1saXN0LXdyYXBwZXIgLlBvbGFyaXMtRGF0YVRhYmxlIHtcbiAgYm9yZGVyLXJhZGl1czogMCAhaW1wb3J0YW50O1xufVxuLmV2ZW50cy1saXN0LXdyYXBwZXIgLlBvbGFyaXMtRGF0YVRhYmxlIHRoZWFkIHRoIHtcbiAgZm9udC13ZWlnaHQ6IDYwMCAhaW1wb3J0YW50O1xuICBmb250LXNpemU6IDEycHg7XG59XG5cbi5ldmVudC1jcmVhdGUtdGFicyAuUG9sYXJpcy1CYWRnZSB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNGRkVGOUQgIWltcG9ydGFudDtcbn0iXX0= */