.event-form__banner-container .Polaris-DropZone.Polaris-DropZone--hasOutline.Polaris-DropZone--hasError {
  width: 70%;
  height: 200px;
}

.event-form__photo-container .Polaris-DropZone.Polaris-DropZone--hasOutline.Polaris-DropZone--hasError {
  width: 200px;
  height: 200px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL2V2ZW50LWZvcm0vc3R5bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsVUFBVTtFQUNWLGFBQWE7QUFDZjs7QUFFQTtFQUNFLFlBQVk7RUFDWixhQUFhO0FBQ2YiLCJmaWxlIjoic3JjL2ZlYXR1cmUvZXZlbnRzL2NvbXBvbmVudHMvZXZlbnQtZm9ybS9zdHlsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZXZlbnQtZm9ybV9fYmFubmVyLWNvbnRhaW5lciAuUG9sYXJpcy1Ecm9wWm9uZS5Qb2xhcmlzLURyb3Bab25lLS1oYXNPdXRsaW5lLlBvbGFyaXMtRHJvcFpvbmUtLWhhc0Vycm9yIHtcbiAgd2lkdGg6IDcwJTtcbiAgaGVpZ2h0OiAyMDBweDtcbn1cblxuLmV2ZW50LWZvcm1fX3Bob3RvLWNvbnRhaW5lciAuUG9sYXJpcy1Ecm9wWm9uZS5Qb2xhcmlzLURyb3Bab25lLS1oYXNPdXRsaW5lLlBvbGFyaXMtRHJvcFpvbmUtLWhhc0Vycm9yIHtcbiAgd2lkdGg6IDIwMHB4O1xuICBoZWlnaHQ6IDIwMHB4O1xufVxuIl19 */