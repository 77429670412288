.top-events-table-wrapper
  .Polaris-IndexTable__Table.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  tr
  th {
  background-color: #FAFAFA !important;
  border-top: 1px solid rgba(128, 128, 128, 0.219);
  padding: 10px;
}
.top-events-table-wrapper
  .Polaris-IndexTable__Table.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  tr
  th
  .Polaris-Text--root.Polaris-Text--bodySm.Polaris-Text--medium {
  font-size: 12px;
}

.top-events-table-wrapper
  .Polaris-IndexTable__Table.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  tr
  th.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable {
  padding-left: 35px !important;
}

.top-events-table-wrapper .Polaris-IndexTable__TableRow {
  color: #5c5f6a !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2hvbWUvY29tcG9uZW50cy90b3AtZXZlbnRzL3N0eWxlcy5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7Ozs7RUFJRSxvQ0FBb0M7RUFDcEMsZ0RBQWdEO0VBQ2hELGFBQWE7QUFDZjtBQUNBOzs7OztFQUtFLGVBQWU7QUFDakI7O0FBRUE7Ozs7RUFJRSw2QkFBNkI7QUFDL0I7O0FBRUE7RUFDRSx5QkFBeUI7QUFDM0IiLCJmaWxlIjoic3JjL2ZlYXR1cmUvaG9tZS9jb21wb25lbnRzL3RvcC1ldmVudHMvc3R5bGVzLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi50b3AtZXZlbnRzLXRhYmxlLXdyYXBwZXJcbiAgLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS0tdW5zZWxlY3RhYmxlLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUtLXN0aWNreVxuICB0clxuICB0aCB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNGQUZBRkEgIWltcG9ydGFudDtcbiAgYm9yZGVyLXRvcDogMXB4IHNvbGlkIHJnYmEoMTI4LCAxMjgsIDEyOCwgMC4yMTkpO1xuICBwYWRkaW5nOiAxMHB4O1xufVxuLnRvcC1ldmVudHMtdGFibGUtd3JhcHBlclxuICAuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlLS11bnNlbGVjdGFibGUuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS0tc3RpY2t5XG4gIHRyXG4gIHRoXG4gIC5Qb2xhcmlzLVRleHQtLXJvb3QuUG9sYXJpcy1UZXh0LS1ib2R5U20uUG9sYXJpcy1UZXh0LS1tZWRpdW0ge1xuICBmb250LXNpemU6IDEycHg7XG59XG5cbi50b3AtZXZlbnRzLXRhYmxlLXdyYXBwZXJcbiAgLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS0tdW5zZWxlY3RhYmxlLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUtLXN0aWNreVxuICB0clxuICB0aC5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy0tc2Vjb25kLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGVIZWFkaW5nLS11bnNlbGVjdGFibGUge1xuICBwYWRkaW5nLWxlZnQ6IDM1cHggIWltcG9ydGFudDtcbn1cblxuLnRvcC1ldmVudHMtdGFibGUtd3JhcHBlciAuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZVJvdyB7XG4gIGNvbG9yOiAjNWM1ZjZhICFpbXBvcnRhbnQ7XG59XG4iXX0= */