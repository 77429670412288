.tickets-select-modal-table-wrapper
  .Polaris-IndexTable__StickyTableHeadings
  .Polaris-Text--root.Polaris-Text--bodySm.Polaris-Text--medium {
  color: #303030 !important;
  font-weight: 550 !important;
  font-size: 13px !important;
}

.tickets-select-modal-table-wrapper .Polaris-IndexTable__TableHeading {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  background-color: #fff !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL3RpY2tldHMtdGFibGUvc3R5bGVzLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTs7O0VBR0UseUJBQXlCO0VBQ3pCLDJCQUEyQjtFQUMzQiwwQkFBMEI7QUFDNUI7O0FBRUE7RUFDRSw0QkFBNEI7RUFDNUIsK0JBQStCO0VBQy9CLGlDQUFpQztBQUNuQyIsImZpbGUiOiJzcmMvZmVhdHVyZS9ldmVudHMvY29tcG9uZW50cy90aWNrZXRzLXRhYmxlL3N0eWxlcy5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIudGlja2V0cy1zZWxlY3QtbW9kYWwtdGFibGUtd3JhcHBlclxuICAuUG9sYXJpcy1JbmRleFRhYmxlX19TdGlja3lUYWJsZUhlYWRpbmdzXG4gIC5Qb2xhcmlzLVRleHQtLXJvb3QuUG9sYXJpcy1UZXh0LS1ib2R5U20uUG9sYXJpcy1UZXh0LS1tZWRpdW0ge1xuICBjb2xvcjogIzMwMzAzMCAhaW1wb3J0YW50O1xuICBmb250LXdlaWdodDogNTUwICFpbXBvcnRhbnQ7XG4gIGZvbnQtc2l6ZTogMTNweCAhaW1wb3J0YW50O1xufVxuXG4udGlja2V0cy1zZWxlY3QtbW9kYWwtdGFibGUtd3JhcHBlciAuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZUhlYWRpbmcge1xuICBwYWRkaW5nLXRvcDogMTJweCAhaW1wb3J0YW50O1xuICBwYWRkaW5nLWJvdHRvbTogMTJweCAhaW1wb3J0YW50O1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmZmICFpbXBvcnRhbnQ7XG59XG4iXX0= */