.customer-info-wrapper
  .Polaris-Avatar.Polaris-Avatar--sizeMd.Polaris-Avatar--imageHasLoaded {
  height: 40px;
  width: 40px;
}
.ticket-img-wrapper .Polaris-Thumbnail.Polaris-Thumbnail--sizeSmall img {
  height: 40px;
  width: 40px;
  -o-object-fit: cover;
     object-fit: cover;
}
.ticket-img-wrapper {
  width: 40px;
}

.paid-badge {
  background-color: #f1f1f1;
  display: flex;
  gap: 2px;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 8px;
  border-radius: 6px;
  font-size: 11px;
}

.paid-badge .Polaris-Icon.Polaris-Icon--toneBase {
  width: 15px !important;
  height: 15px !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL29yZGVycy9jb21wb25lbnRzL2RldGFpbHMvc3R5bGVzLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTs7RUFFRSxZQUFZO0VBQ1osV0FBVztBQUNiO0FBQ0E7RUFDRSxZQUFZO0VBQ1osV0FBVztFQUNYLG9CQUFpQjtLQUFqQixpQkFBaUI7QUFDbkI7QUFDQTtFQUNFLFdBQVc7QUFDYjs7QUFFQTtFQUNFLHlCQUF5QjtFQUN6QixhQUFhO0VBQ2IsUUFBUTtFQUNSLG1CQUFtQjtFQUNuQix1QkFBa0I7RUFBbEIsa0JBQWtCO0VBQ2xCLGdCQUFnQjtFQUNoQixrQkFBa0I7RUFDbEIsZUFBZTtBQUNqQjs7QUFFQTtFQUNFLHNCQUFzQjtFQUN0Qix1QkFBdUI7QUFDekIiLCJmaWxlIjoic3JjL2ZlYXR1cmUvb3JkZXJzL2NvbXBvbmVudHMvZGV0YWlscy9zdHlsZXMuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmN1c3RvbWVyLWluZm8td3JhcHBlclxuICAuUG9sYXJpcy1BdmF0YXIuUG9sYXJpcy1BdmF0YXItLXNpemVNZC5Qb2xhcmlzLUF2YXRhci0taW1hZ2VIYXNMb2FkZWQge1xuICBoZWlnaHQ6IDQwcHg7XG4gIHdpZHRoOiA0MHB4O1xufVxuLnRpY2tldC1pbWctd3JhcHBlciAuUG9sYXJpcy1UaHVtYm5haWwuUG9sYXJpcy1UaHVtYm5haWwtLXNpemVTbWFsbCBpbWcge1xuICBoZWlnaHQ6IDQwcHg7XG4gIHdpZHRoOiA0MHB4O1xuICBvYmplY3QtZml0OiBjb3Zlcjtcbn1cbi50aWNrZXQtaW1nLXdyYXBwZXIge1xuICB3aWR0aDogNDBweDtcbn1cblxuLnBhaWQtYmFkZ2Uge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZjFmMWYxO1xuICBkaXNwbGF5OiBmbGV4O1xuICBnYXA6IDJweDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgd2lkdGg6IGZpdC1jb250ZW50O1xuICBwYWRkaW5nOiAzcHggOHB4O1xuICBib3JkZXItcmFkaXVzOiA2cHg7XG4gIGZvbnQtc2l6ZTogMTFweDtcbn1cblxuLnBhaWQtYmFkZ2UgLlBvbGFyaXMtSWNvbi5Qb2xhcmlzLUljb24tLXRvbmVCYXNlIHtcbiAgd2lkdGg6IDE1cHggIWltcG9ydGFudDtcbiAgaGVpZ2h0OiAxNXB4ICFpbXBvcnRhbnQ7XG59XG4iXX0= */