.marketing-activity-list-wrapper
  .Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--unselectable {
  color: #202224;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}

.marketing-activity-list-wrapper
  .Polaris-Text--root.Polaris-Text--bodySm.Polaris-Text--medium {
  font-size: 13px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL21hcmtldGluZy9jb21wb25lbnRzL3RhYmxlL3N0eWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTs7RUFFRSxjQUFjO0VBQ2QsaUJBQWlCO0VBQ2pCLG9CQUFvQjtFQUNwQixzQkFBc0I7QUFDeEI7O0FBRUE7O0VBRUUsZUFBZTtBQUNqQiIsImZpbGUiOiJzcmMvZmVhdHVyZS9tYXJrZXRpbmcvY29tcG9uZW50cy90YWJsZS9zdHlsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubWFya2V0aW5nLWFjdGl2aXR5LWxpc3Qtd3JhcHBlclxuICAuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZUhlYWRpbmcuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZUhlYWRpbmcuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZUhlYWRpbmctLXVuc2VsZWN0YWJsZSB7XG4gIGNvbG9yOiAjMjAyMjI0O1xuICBwYWRkaW5nLXRvcDogMjBweDtcbiAgcGFkZGluZy1ib3R0b206IDIwcHg7XG4gIGJhY2tncm91bmQtY29sb3I6ICNmZmY7XG59XG5cbi5tYXJrZXRpbmctYWN0aXZpdHktbGlzdC13cmFwcGVyXG4gIC5Qb2xhcmlzLVRleHQtLXJvb3QuUG9sYXJpcy1UZXh0LS1ib2R5U20uUG9sYXJpcy1UZXh0LS1tZWRpdW0ge1xuICBmb250LXNpemU6IDEzcHg7XG59XG4iXX0= */