.event-options-popover-activator .Polaris-Button.Polaris-Button--pressable.Polaris-Button--variantSecondary.Polaris-Button--sizeLarge.Polaris-Button--textAlignCenter.Polaris-Button--disclosure {
    color: #303030 !important;
    background: #E3E3E3 !important;
    border-radius: 9.6355px !important;
    box-shadow: none !important;
    padding: 6.5px 12px !important;
    font-size: 13px !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL2V2ZW50LW9wdGlvbnMtcG9wb3Zlci9zdHlsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7SUFDSSx5QkFBeUI7SUFDekIsOEJBQThCO0lBQzlCLGtDQUFrQztJQUNsQywyQkFBMkI7SUFDM0IsOEJBQThCO0lBQzlCLDBCQUEwQjtJQUMxQixtQ0FBOEI7SUFBOUIsOEJBQThCO0FBQ2xDIiwiZmlsZSI6InNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL2V2ZW50LW9wdGlvbnMtcG9wb3Zlci9zdHlsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZXZlbnQtb3B0aW9ucy1wb3BvdmVyLWFjdGl2YXRvciAuUG9sYXJpcy1CdXR0b24uUG9sYXJpcy1CdXR0b24tLXByZXNzYWJsZS5Qb2xhcmlzLUJ1dHRvbi0tdmFyaWFudFNlY29uZGFyeS5Qb2xhcmlzLUJ1dHRvbi0tc2l6ZUxhcmdlLlBvbGFyaXMtQnV0dG9uLS10ZXh0QWxpZ25DZW50ZXIuUG9sYXJpcy1CdXR0b24tLWRpc2Nsb3N1cmUge1xuICAgIGNvbG9yOiAjMzAzMDMwICFpbXBvcnRhbnQ7XG4gICAgYmFja2dyb3VuZDogI0UzRTNFMyAhaW1wb3J0YW50O1xuICAgIGJvcmRlci1yYWRpdXM6IDkuNjM1NXB4ICFpbXBvcnRhbnQ7XG4gICAgYm94LXNoYWRvdzogbm9uZSAhaW1wb3J0YW50O1xuICAgIHBhZGRpbmc6IDYuNXB4IDEycHggIWltcG9ydGFudDtcbiAgICBmb250LXNpemU6IDEzcHggIWltcG9ydGFudDtcbiAgICBoZWlnaHQ6IGZpdC1jb250ZW50ICFpbXBvcnRhbnQ7XG59Il19 */