.wrapped-select-field .multi-select .select__control {
  border-radius: 5px;
}

.wrapped-select-field .select__menu {
     z-index: 999;
}
.wrapped-select-field .multi-select .select__multi-value{
     border-radius: 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2Zvcm0vc2VsZWN0LXN0eWxlcy5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxrQkFBa0I7QUFDcEI7O0FBRUE7S0FDSyxZQUFZO0FBQ2pCO0FBQ0E7S0FDSyxrQkFBa0I7QUFDdkIiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvZm9ybS9zZWxlY3Qtc3R5bGVzLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi53cmFwcGVkLXNlbGVjdC1maWVsZCAubXVsdGktc2VsZWN0IC5zZWxlY3RfX2NvbnRyb2wge1xuICBib3JkZXItcmFkaXVzOiA1cHg7XG59XG5cbi53cmFwcGVkLXNlbGVjdC1maWVsZCAuc2VsZWN0X19tZW51IHtcbiAgICAgei1pbmRleDogOTk5O1xufVxuLndyYXBwZWQtc2VsZWN0LWZpZWxkIC5tdWx0aS1zZWxlY3QgLnNlbGVjdF9fbXVsdGktdmFsdWV7XG4gICAgIGJvcmRlci1yYWRpdXM6IDVweDtcbn0iXX0= */