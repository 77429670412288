.grid-cols-custom {
  grid-template-columns: 1fr 2fr;
}

@media (max-width: 768px) {
  .grid-cols-custom {
    grid-template-columns: 1fr;
  }
}

.modal-container {
  transform: translateY(90%);
  transition: transform 1s ease-in;
}

.modal-container.active {
  transform: translateY(0);
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;             /* Chrome, Safari and Opera */
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2xheW91dC9zdHlsZXMuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsOEJBQThCO0FBQ2hDOztBQUVBO0VBQ0U7SUFDRSwwQkFBMEI7RUFDNUI7QUFDRjs7QUFFQTtFQUNFLDBCQUEwQjtFQUMxQixnQ0FBZ0M7QUFDbEM7O0FBRUE7RUFDRSx3QkFBd0I7QUFDMUI7O0FBRUE7RUFDRSx3QkFBd0IsR0FBRyxnQkFBZ0I7RUFDM0MscUJBQXFCLE1BQU0sWUFBWTtBQUN6Qzs7QUFFQTtFQUNFLGFBQWEsY0FBYyw2QkFBNkI7QUFDMUQiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbGF5b3V0L3N0eWxlcy5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZ3JpZC1jb2xzLWN1c3RvbSB7XG4gIGdyaWQtdGVtcGxhdGUtY29sdW1uczogMWZyIDJmcjtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDc2OHB4KSB7XG4gIC5ncmlkLWNvbHMtY3VzdG9tIHtcbiAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IDFmcjtcbiAgfVxufVxuXG4ubW9kYWwtY29udGFpbmVyIHtcbiAgdHJhbnNmb3JtOiB0cmFuc2xhdGVZKDkwJSk7XG4gIHRyYW5zaXRpb246IHRyYW5zZm9ybSAxcyBlYXNlLWluO1xufVxuXG4ubW9kYWwtY29udGFpbmVyLmFjdGl2ZSB7XG4gIHRyYW5zZm9ybTogdHJhbnNsYXRlWSgwKTtcbn1cblxuLnNjcm9sbGJhci1oaWRlIHtcbiAgLW1zLW92ZXJmbG93LXN0eWxlOiBub25lOyAgLyogSUUgYW5kIEVkZ2UgKi9cbiAgc2Nyb2xsYmFyLXdpZHRoOiBub25lOyAgICAgLyogRmlyZWZveCAqL1xufVxuXG4uc2Nyb2xsYmFyLWhpZGU6Oi13ZWJraXQtc2Nyb2xsYmFyIHtcbiAgZGlzcGxheTogbm9uZTsgICAgICAgICAgICAgLyogQ2hyb21lLCBTYWZhcmkgYW5kIE9wZXJhICovXG59XG4iXX0= */