.selected-tickets-wrapper
  .Polaris-Connected__Item.Polaris-Connected__Item--primary:has(
    .Polaris-TextField__Input:disabled
  ) {
  background: #fff !important;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
}

.selected-tickets-wrapper .Polaris-Label {
  color: #303030;
}

.Polaris-TextField__Input:disabled {
  color: #616161 !important;
  -webkit-text-fill-color: #616161 !important;
}

.quantity-input-wrapper
  .Polaris-Connected__Item.Polaris-Connected__Item--primary:has(
    .Polaris-TextField__Input:disabled
  ) {
  /* background: rgba(255, 0, 0, 0.175) !important; */
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL2FkZC10aWNrZXQvc3R5bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBOzs7O0VBSUUsMkJBQTJCO0VBQzNCLGtCQUFrQjtFQUNsQix5QkFBeUI7QUFDM0I7O0FBRUE7RUFDRSxjQUFjO0FBQ2hCOztBQUVBO0VBQ0UseUJBQXlCO0VBQ3pCLDJDQUEyQztBQUM3Qzs7QUFFQTs7OztFQUlFLG1EQUFtRDtBQUNyRCIsImZpbGUiOiJzcmMvZmVhdHVyZS9ldmVudHMvY29tcG9uZW50cy9hZGQtdGlja2V0L3N0eWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5zZWxlY3RlZC10aWNrZXRzLXdyYXBwZXJcbiAgLlBvbGFyaXMtQ29ubmVjdGVkX19JdGVtLlBvbGFyaXMtQ29ubmVjdGVkX19JdGVtLS1wcmltYXJ5OmhhcyhcbiAgICAuUG9sYXJpcy1UZXh0RmllbGRfX0lucHV0OmRpc2FibGVkXG4gICkge1xuICBiYWNrZ3JvdW5kOiAjZmZmICFpbXBvcnRhbnQ7XG4gIGJvcmRlci1yYWRpdXM6IDhweDtcbiAgYm9yZGVyOiAxcHggc29saWQgI2I1YjViNTtcbn1cblxuLnNlbGVjdGVkLXRpY2tldHMtd3JhcHBlciAuUG9sYXJpcy1MYWJlbCB7XG4gIGNvbG9yOiAjMzAzMDMwO1xufVxuXG4uUG9sYXJpcy1UZXh0RmllbGRfX0lucHV0OmRpc2FibGVkIHtcbiAgY29sb3I6ICM2MTYxNjEgIWltcG9ydGFudDtcbiAgLXdlYmtpdC10ZXh0LWZpbGwtY29sb3I6ICM2MTYxNjEgIWltcG9ydGFudDtcbn1cblxuLnF1YW50aXR5LWlucHV0LXdyYXBwZXJcbiAgLlBvbGFyaXMtQ29ubmVjdGVkX19JdGVtLlBvbGFyaXMtQ29ubmVjdGVkX19JdGVtLS1wcmltYXJ5OmhhcyhcbiAgICAuUG9sYXJpcy1UZXh0RmllbGRfX0lucHV0OmRpc2FibGVkXG4gICkge1xuICAvKiBiYWNrZ3JvdW5kOiByZ2JhKDI1NSwgMCwgMCwgMC4xNzUpICFpbXBvcnRhbnQ7ICovXG59XG4iXX0= */