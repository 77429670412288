.events-list-wrapper
  .Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--unselectable {
  color: #202224;
}

.events-list-wrapper
  .Polaris-IndexTable__Table.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  tr
  th.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable {
  padding-left: 25px !important;
}

.events-list-content-wrapper .Polaris-Tabs {
  padding-left: 15px !important;
}

.Polaris-Avatar.Polaris-Avatar--sizeMd {
  background: #fbcffb;
  color: #791a79;
}

.blurred-col {
  filter: blur(1px) !important;
  opacity: 0.5 !important;
}


.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.p-4 {
  padding: 1rem;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL2V2ZW50cy1saXN0LXRhYmxlL3N0eWxlcy5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7O0VBRUUsY0FBYztBQUNoQjs7QUFFQTs7OztFQUlFLDZCQUE2QjtBQUMvQjs7QUFFQTtFQUNFLDZCQUE2QjtBQUMvQjs7QUFFQTtFQUNFLG1CQUFtQjtFQUNuQixjQUFjO0FBQ2hCOztBQUVBO0VBQ0UsNEJBQTRCO0VBQzVCLHVCQUF1QjtBQUN6Qjs7O0FBR0E7RUFDRSxhQUFhO0FBQ2Y7O0FBRUE7RUFDRSx1QkFBdUI7QUFDekI7O0FBRUE7RUFDRSxhQUFhO0FBQ2YiLCJmaWxlIjoic3JjL2ZlYXR1cmUvZXZlbnRzL2NvbXBvbmVudHMvZXZlbnRzLWxpc3QtdGFibGUvc3R5bGVzLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5ldmVudHMtbGlzdC13cmFwcGVyXG4gIC5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy0tdW5zZWxlY3RhYmxlIHtcbiAgY29sb3I6ICMyMDIyMjQ7XG59XG5cbi5ldmVudHMtbGlzdC13cmFwcGVyXG4gIC5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUtLXVuc2VsZWN0YWJsZS5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlLS1zdGlja3lcbiAgdHJcbiAgdGguUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZUhlYWRpbmcuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZUhlYWRpbmctLXNlY29uZC5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy0tdW5zZWxlY3RhYmxlIHtcbiAgcGFkZGluZy1sZWZ0OiAyNXB4ICFpbXBvcnRhbnQ7XG59XG5cbi5ldmVudHMtbGlzdC1jb250ZW50LXdyYXBwZXIgLlBvbGFyaXMtVGFicyB7XG4gIHBhZGRpbmctbGVmdDogMTVweCAhaW1wb3J0YW50O1xufVxuXG4uUG9sYXJpcy1BdmF0YXIuUG9sYXJpcy1BdmF0YXItLXNpemVNZCB7XG4gIGJhY2tncm91bmQ6ICNmYmNmZmI7XG4gIGNvbG9yOiAjNzkxYTc5O1xufVxuXG4uYmx1cnJlZC1jb2wge1xuICBmaWx0ZXI6IGJsdXIoMXB4KSAhaW1wb3J0YW50O1xuICBvcGFjaXR5OiAwLjUgIWltcG9ydGFudDtcbn1cblxuXG4uZmxleCB7XG4gIGRpc3BsYXk6IGZsZXg7XG59XG5cbi5qdXN0aWZ5LWNlbnRlciB7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xufVxuXG4ucC00IHtcbiAgcGFkZGluZzogMXJlbTtcbn1cbiJdfQ== */