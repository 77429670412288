.selected-tickets-list-wrapper
  .Polaris-IndexTable__Table.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  thead
  tr
  th {
  border: none !important;
  background-color: #ffffff !important;
}

.selected-tickets-list-wrapper
  .Polaris-IndexTable__Table.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  .Polaris-Text--root.Polaris-Text--bodySm.Polaris-Text--medium {
  color: #000;
  font-weight: 600;
  font-size: 13px;
}

.selected-tickets-list-wrapper .Polaris-IndexTable__TableCell:first-child {
  width: 62.5%;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL29yZGVycy9jb21wb25lbnRzL2V2ZW50cy10aWNrZXRzLWFkZC1jYXJkL3N0eWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTs7Ozs7RUFLRSx1QkFBdUI7RUFPdkIsb0NBQW9DO0FBTnRDOztBQVNBOzs7RUFHRSxXQUFXO0VBQ1gsZ0JBQWdCO0VBQ2hCLGVBQWU7QUFDakI7O0FBRUE7RUFDRSxZQUFZO0FBQ2QiLCJmaWxlIjoic3JjL2ZlYXR1cmUvb3JkZXJzL2NvbXBvbmVudHMvZXZlbnRzLXRpY2tldHMtYWRkLWNhcmQvc3R5bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnNlbGVjdGVkLXRpY2tldHMtbGlzdC13cmFwcGVyXG4gIC5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUtLXVuc2VsZWN0YWJsZS5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlLS1zdGlja3lcbiAgdGhlYWRcbiAgdHJcbiAgdGgge1xuICBib3JkZXI6IG5vbmUgIWltcG9ydGFudDtcbn1cbi5zZWxlY3RlZC10aWNrZXRzLWxpc3Qtd3JhcHBlclxuICAuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlLS11bnNlbGVjdGFibGUuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS0tc3RpY2t5XG4gIHRoZWFkXG4gIHRyXG4gIHRoIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ZmZmZmZiAhaW1wb3J0YW50O1xufVxuXG4uc2VsZWN0ZWQtdGlja2V0cy1saXN0LXdyYXBwZXJcbiAgLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS0tdW5zZWxlY3RhYmxlLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUtLXN0aWNreVxuICAuUG9sYXJpcy1UZXh0LS1yb290LlBvbGFyaXMtVGV4dC0tYm9keVNtLlBvbGFyaXMtVGV4dC0tbWVkaXVtIHtcbiAgY29sb3I6ICMwMDA7XG4gIGZvbnQtd2VpZ2h0OiA2MDA7XG4gIGZvbnQtc2l6ZTogMTNweDtcbn1cblxuLnNlbGVjdGVkLXRpY2tldHMtbGlzdC13cmFwcGVyIC5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlQ2VsbDpmaXJzdC1jaGlsZCB7XG4gIHdpZHRoOiA2Mi41JTtcbn1cbiJdfQ== */