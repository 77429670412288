.invite-modal button.Polaris-Button.Polaris-Button--iconOnly[aria-label="Close"] {
  display: none;
}

.invite-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.invite-expiry {
  font-size: 14px;
  font-weight: 300;
  color: #9CA3AF;
}

.invite-buttons {
  display: flex;
  gap: 12px;
}

@media screen and (max-width: 640px) {
  .invite-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .invite-buttons {
    width: 100%;
  }

  .invite-buttons > div {
    flex: 1;
  }

  .invite-buttons button {
    width: 100%;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2ludml0ZXMvY29tcG9uZW50cy9wZW5kaW5nLWludml0ZS9zdHlsZXMuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBYTtBQUNmOztBQUVBO0VBQ0UsYUFBYTtFQUNiLDhCQUE4QjtFQUM5QixtQkFBbUI7RUFDbkIsU0FBUztBQUNYOztBQUVBO0VBQ0UsZUFBZTtFQUNmLGdCQUFnQjtFQUNoQixjQUFjO0FBQ2hCOztBQUVBO0VBQ0UsYUFBYTtFQUNiLFNBQVM7QUFDWDs7QUFFQTtFQUNFO0lBQ0Usc0JBQXNCO0lBQ3RCLHVCQUF1QjtFQUN6Qjs7RUFFQTtJQUNFLFdBQVc7RUFDYjs7RUFFQTtJQUNFLE9BQU87RUFDVDs7RUFFQTtJQUNFLFdBQVc7RUFDYjtBQUNGIiwiZmlsZSI6InNyYy9mZWF0dXJlL2ludml0ZXMvY29tcG9uZW50cy9wZW5kaW5nLWludml0ZS9zdHlsZXMuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmludml0ZS1tb2RhbCBidXR0b24uUG9sYXJpcy1CdXR0b24uUG9sYXJpcy1CdXR0b24tLWljb25Pbmx5W2FyaWEtbGFiZWw9XCJDbG9zZVwiXSB7XG4gIGRpc3BsYXk6IG5vbmU7XG59XG5cbi5pbnZpdGUtYWN0aW9ucyB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgZ2FwOiAxNnB4O1xufVxuXG4uaW52aXRlLWV4cGlyeSB7XG4gIGZvbnQtc2l6ZTogMTRweDtcbiAgZm9udC13ZWlnaHQ6IDMwMDtcbiAgY29sb3I6ICM5Q0EzQUY7XG59XG5cbi5pbnZpdGUtYnV0dG9ucyB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGdhcDogMTJweDtcbn1cblxuQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogNjQwcHgpIHtcbiAgLmludml0ZS1hY3Rpb25zIHtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGFsaWduLWl0ZW1zOiBmbGV4LXN0YXJ0O1xuICB9XG5cbiAgLmludml0ZS1idXR0b25zIHtcbiAgICB3aWR0aDogMTAwJTtcbiAgfVxuXG4gIC5pbnZpdGUtYnV0dG9ucyA+IGRpdiB7XG4gICAgZmxleDogMTtcbiAgfVxuXG4gIC5pbnZpdGUtYnV0dG9ucyBidXR0b24ge1xuICAgIHdpZHRoOiAxMDAlO1xuICB9XG59XG4iXX0= */