.phone-number-container {
  width: 100%;
  z-index: 99 !important;
  margin-left: 3rem;
}

.phone-number-container .flag-dropdown {
  left: -3rem;
  border: 1px solid rgba(131, 128, 128, 0.837) !important;
  border-radius: 8px;
  background-color: #f3f4f6 !important;
}

.phone-number-container .flag-dropdown .selected-flag:hover {
  border-radius: 8px;
  background-color: transparent !important;
}

.phone-number-input {
  width: calc(100% - 3rem) !important;
  border: 1px solid rgba(131, 128, 128, 0.837) !important;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f3f4f6;
  margin-left: 0 !important;
  padding-left: 0.5rem !important;
}

.phone-number-dropdown {
  background-color: #f3f4f6;
}

.country-code-search input {
  background-color: #f3f4f6 !important;
  margin: 0 !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2Zvcm0vcGhvbmUtbnVtYmVyLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFdBQVc7RUFDWCxzQkFBc0I7RUFDdEIsaUJBQWlCO0FBQ25COztBQUVBO0VBQ0UsV0FBVztFQUNYLHVEQUF1RDtFQUN2RCxrQkFBa0I7RUFDbEIsb0NBQW9DO0FBQ3RDOztBQUVBO0VBQ0Usa0JBQWtCO0VBQ2xCLHdDQUF3QztBQUMxQzs7QUFFQTtFQUNFLG1DQUFtQztFQUNuQyx1REFBdUQ7RUFDdkQsa0JBQWtCO0VBQ2xCLGdCQUFnQjtFQUNoQix5QkFBeUI7RUFDekIseUJBQXlCO0VBQ3pCLCtCQUErQjtBQUNqQzs7QUFFQTtFQUNFLHlCQUF5QjtBQUMzQjs7QUFFQTtFQUNFLG9DQUFvQztFQUNwQyxvQkFBb0I7QUFDdEIiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvZm9ybS9waG9uZS1udW1iZXIuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnBob25lLW51bWJlci1jb250YWluZXIge1xuICB3aWR0aDogMTAwJTtcbiAgei1pbmRleDogOTkgIWltcG9ydGFudDtcbiAgbWFyZ2luLWxlZnQ6IDNyZW07XG59XG5cbi5waG9uZS1udW1iZXItY29udGFpbmVyIC5mbGFnLWRyb3Bkb3duIHtcbiAgbGVmdDogLTNyZW07XG4gIGJvcmRlcjogMXB4IHNvbGlkIHJnYmEoMTMxLCAxMjgsIDEyOCwgMC44MzcpICFpbXBvcnRhbnQ7XG4gIGJvcmRlci1yYWRpdXM6IDhweDtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2YzZjRmNiAhaW1wb3J0YW50O1xufVxuXG4ucGhvbmUtbnVtYmVyLWNvbnRhaW5lciAuZmxhZy1kcm9wZG93biAuc2VsZWN0ZWQtZmxhZzpob3ZlciB7XG4gIGJvcmRlci1yYWRpdXM6IDhweDtcbiAgYmFja2dyb3VuZC1jb2xvcjogdHJhbnNwYXJlbnQgIWltcG9ydGFudDtcbn1cblxuLnBob25lLW51bWJlci1pbnB1dCB7XG4gIHdpZHRoOiBjYWxjKDEwMCUgLSAzcmVtKSAhaW1wb3J0YW50O1xuICBib3JkZXI6IDFweCBzb2xpZCByZ2JhKDEzMSwgMTI4LCAxMjgsIDAuODM3KSAhaW1wb3J0YW50O1xuICBib3JkZXItcmFkaXVzOiA4cHg7XG4gIG92ZXJmbG93OiBoaWRkZW47XG4gIGJhY2tncm91bmQtY29sb3I6ICNmM2Y0ZjY7XG4gIG1hcmdpbi1sZWZ0OiAwICFpbXBvcnRhbnQ7XG4gIHBhZGRpbmctbGVmdDogMC41cmVtICFpbXBvcnRhbnQ7XG59XG5cbi5waG9uZS1udW1iZXItZHJvcGRvd24ge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZjNmNGY2O1xufVxuXG4uY291bnRyeS1jb2RlLXNlYXJjaCBpbnB1dCB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNmM2Y0ZjYgIWltcG9ydGFudDtcbiAgbWFyZ2luOiAwICFpbXBvcnRhbnQ7XG59XG4iXX0= */