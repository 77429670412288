.orders-table-wrapper
  .Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--unselectable {
  padding-top: 13px;
  padding-bottom: 10px;
}

.orders-table-wrapper
  .Polaris-IndexTable__Table.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  tr
  th.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable {
  padding-left: 25px !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL29yZGVycy9jb21wb25lbnRzL3RhYmxlL3N0eWxlcy5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7O0VBRUUsaUJBQWlCO0VBQ2pCLG9CQUFvQjtBQUN0Qjs7QUFFQTs7OztFQUlFLDZCQUE2QjtBQUMvQiIsImZpbGUiOiJzcmMvZmVhdHVyZS9vcmRlcnMvY29tcG9uZW50cy90YWJsZS9zdHlsZXMuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm9yZGVycy10YWJsZS13cmFwcGVyXG4gIC5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy0tdW5zZWxlY3RhYmxlIHtcbiAgcGFkZGluZy10b3A6IDEzcHg7XG4gIHBhZGRpbmctYm90dG9tOiAxMHB4O1xufVxuXG4ub3JkZXJzLXRhYmxlLXdyYXBwZXJcbiAgLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS0tdW5zZWxlY3RhYmxlLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUtLXN0aWNreVxuICB0clxuICB0aC5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlSGVhZGluZy0tc2Vjb25kLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGVIZWFkaW5nLS11bnNlbGVjdGFibGUge1xuICBwYWRkaW5nLWxlZnQ6IDI1cHggIWltcG9ydGFudDtcbn1cbiJdfQ== */