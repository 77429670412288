.merchandise-products .Polaris-IndexTable {
  margin-top: -25px;
}

.merchandise-products .Polaris-IndexTable__StickyTableHeadings {
  display: none;
}

.merchandise-products table thead {
  /* display: none; */
  opacity: 0;
}

.merchandise-products .Polaris-IndexTable__BulkActionsWrapper {
  display: none;
  opacity: 0;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL21lcmNoYW5kaXNlL3N0eWxlcy5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxpQkFBaUI7QUFDbkI7O0FBRUE7RUFDRSxhQUFhO0FBQ2Y7O0FBRUE7RUFDRSxtQkFBbUI7RUFDbkIsVUFBVTtBQUNaOztBQUVBO0VBQ0UsYUFBYTtFQUNiLFVBQVU7QUFDWiIsImZpbGUiOiJzcmMvZmVhdHVyZS9ldmVudHMvY29tcG9uZW50cy9tZXJjaGFuZGlzZS9zdHlsZXMuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1lcmNoYW5kaXNlLXByb2R1Y3RzIC5Qb2xhcmlzLUluZGV4VGFibGUge1xuICBtYXJnaW4tdG9wOiAtMjVweDtcbn1cblxuLm1lcmNoYW5kaXNlLXByb2R1Y3RzIC5Qb2xhcmlzLUluZGV4VGFibGVfX1N0aWNreVRhYmxlSGVhZGluZ3Mge1xuICBkaXNwbGF5OiBub25lO1xufVxuXG4ubWVyY2hhbmRpc2UtcHJvZHVjdHMgdGFibGUgdGhlYWQge1xuICAvKiBkaXNwbGF5OiBub25lOyAqL1xuICBvcGFjaXR5OiAwO1xufVxuXG4ubWVyY2hhbmRpc2UtcHJvZHVjdHMgLlBvbGFyaXMtSW5kZXhUYWJsZV9fQnVsa0FjdGlvbnNXcmFwcGVyIHtcbiAgZGlzcGxheTogbm9uZTtcbiAgb3BhY2l0eTogMDtcbn1cbiJdfQ== */