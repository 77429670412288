.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.quill .ql-toolbar {
  border-radius: 8px 8px 0 0 !important;
  background-color: #f3f3f3;
  color: #4a4a4a;
}

.quill-wrapper .ql-container {
  border: none;
  font-family: inherit;
}

.quill-wrapper .ql-editor {
  min-height: 150px;
  font-size: 14px;
  line-height: 1.5;
}

.custom-quill .ql-toolbar button {
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-quill .ql-toolbar button:hover {
  background-color: #e5e7eb;
}

.custom-quill .ql-toolbar .ql-active {
  background-color: #e5e7eb;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2Zvcm0vcXVpbGwuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsaUJBQWlCO0VBQ2pCLFlBQVk7RUFDWixPQUFPO0VBQ1AsYUFBYTtFQUNiLHNCQUFzQjtFQUN0QiwwQkFBMEI7QUFDNUI7O0FBRUE7RUFDRSxZQUFZO0VBQ1osT0FBTztFQUNQLGdCQUFnQjtFQUNoQixXQUFXO0FBQ2I7O0FBRUE7RUFDRSxxQ0FBcUM7RUFDckMseUJBQXlCO0VBQ3pCLGNBQWM7QUFDaEI7O0FBRUE7RUFDRSxZQUFZO0VBQ1osb0JBQW9CO0FBQ3RCOztBQUVBO0VBQ0UsaUJBQWlCO0VBQ2pCLGVBQWU7RUFDZixnQkFBZ0I7QUFDbEI7O0FBRUE7RUFDRSxXQUFXO0VBQ1gsWUFBWTtFQUNaLG9CQUFvQjtFQUNwQixtQkFBbUI7RUFDbkIsdUJBQXVCO0FBQ3pCOztBQUVBO0VBQ0UseUJBQXlCO0FBQzNCOztBQUVBO0VBQ0UseUJBQXlCO0FBQzNCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL2Zvcm0vcXVpbGwuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnFsLWNvbnRhaW5lciB7XG4gIG1pbi1oZWlnaHQ6IDEwcmVtO1xuICBoZWlnaHQ6IDEwMCU7XG4gIGZsZXg6IDE7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGJvcmRlci1yYWRpdXM6IDAgMCA4cHggOHB4O1xufVxuXG4ucWwtZWRpdG9yIHtcbiAgaGVpZ2h0OiAxMDAlO1xuICBmbGV4OiAxO1xuICBvdmVyZmxvdy15OiBhdXRvO1xuICB3aWR0aDogMTAwJTtcbn1cblxuLnF1aWxsIC5xbC10b29sYmFyIHtcbiAgYm9yZGVyLXJhZGl1czogOHB4IDhweCAwIDAgIWltcG9ydGFudDtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2YzZjNmMztcbiAgY29sb3I6ICM0YTRhNGE7XG59XG5cbi5xdWlsbC13cmFwcGVyIC5xbC1jb250YWluZXIge1xuICBib3JkZXI6IG5vbmU7XG4gIGZvbnQtZmFtaWx5OiBpbmhlcml0O1xufVxuXG4ucXVpbGwtd3JhcHBlciAucWwtZWRpdG9yIHtcbiAgbWluLWhlaWdodDogMTUwcHg7XG4gIGZvbnQtc2l6ZTogMTRweDtcbiAgbGluZS1oZWlnaHQ6IDEuNTtcbn1cblxuLmN1c3RvbS1xdWlsbCAucWwtdG9vbGJhciBidXR0b24ge1xuICB3aWR0aDogMjhweDtcbiAgaGVpZ2h0OiAyOHB4O1xuICBkaXNwbGF5OiBpbmxpbmUtZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG59XG5cbi5jdXN0b20tcXVpbGwgLnFsLXRvb2xiYXIgYnV0dG9uOmhvdmVyIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2U1ZTdlYjtcbn1cblxuLmN1c3RvbS1xdWlsbCAucWwtdG9vbGJhciAucWwtYWN0aXZlIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2U1ZTdlYjtcbn1cbiJdfQ== */